import { gql } from '@apollo/client';

export const getMarketplaceList = gql`
    query getMarketplaceList($search: String, $filter: MarketplaceFilterInput) {
        getMarketplaceList(search: $search, pageSize: null, filter: $filter) {
            items {
                image_url
                marketplace_code
                marketplace_name
                features
                is_active
                is_open_api
            }
        }
    }
`;

export const getSellerMpCredentials = gql`
query getSellerMpCredentials(
    $marketplace_code: String!
    $callback_url: String!
    $brand_id: String!
  ) {
    getSellerMpCredentials(
      marketplace_code: $marketplace_code
      callback_url: $callback_url
      brand_id: $brand_id
    ) {
      brand_id
      marketplaces {
        credentials {
          channel_code
          channel_name
          fields {
            description
            name
            tooltip
            required
            type
          }
          type
          url
          url_callback
        }
        features
        image_url
        marketplace_code
        marketplace_name
      }
    }
  }
  
`;

export const getMarketplaceDefaultShippingMethods = gql`
    query getMarketplaceDefaultShippingMethods($marketplace_code: String!){
        getMarketplaceDefaultShippingMethods(marketplace_code: $marketplace_code){
            label
            value
        }
    }
`;

export const connectSellerMp = gql`
  mutation connectSellerMp($input: IntegrateSellerMpInput!) {
    connectSellerMp(input: $input)
  }
`;

export const requestSellerMarketplaceIntegration = gql`
  mutation requestSellerMarketplaceIntegration($input: MarketplaceItegrationRequestInput!) {
    requestSellerMarketplaceIntegration(input: $input)
  }
`;

export const getWebstoreList = gql`
  query getWebstoreList {
    getWebstoreList {
      framework
      image_url
      is_active
      is_multiseller
      webstore_code
      webstore_name
      }
  }
`;

export const getWebstoreConfiguration = gql`
  query getWebstoreConfiguration {
    getWebstoreConfiguration {
      fields {
        id
        value
      }
    }
  }
`;

export const createWebstore = gql`
  mutation createWebstore($input: CreateWebStoreInput) {
    createWebstore( input: $input ) {
      message
      success
    }
  }
`;

export const registerBrandMp = gql`
  mutation registerBrandMp($marketplace_code: String!) {
    registerBrandMp(marketplace_code: $marketplace_code)
  }
`;

export const integrateKatalisAccount = gql`
  mutation integrateKatalisAccount($email: String!, $password: String!) {
    integrateKatalisAccount(input: { email: $email, password: $password })
  }
`;

export const disconnectKatalisAccount = gql`
  mutation disconnectKatalisAccount {
    disconnectKatalisAccount
  }
`;

export const getKatalisImageBackgroundList = gql`
  query getKatalisImageBackgroundList {
    getKatalisImageBackgroundList {
      background {
        id
        name
        thumbnail
      }
      category
    }
  }
`;

export const getKatalisCreditBalance = gql`
  query getKatalisCreditBalance {
    getKatalisCreditBalance
  }
`;

export const generateKatalisCopy = gql`
  mutation generateKatalisCopy(
    $detail: String!
    $keywords: [String]
    $language: String!
    $type: GenerateKatalisCopyType!
  ) {
    generateKatalisCopy(input: {
      detail: $detail
      keywords: $keywords
      language: $language
      type: $type
    }) {
      description
      features
      title
    }
  }
`;

export const getGeneratedKatalisImagesbySKU = gql`
    query getGeneratedKatalisImagesbySKU($sku: String!) {
      getGeneratedKatalisImagesbySKU(sku: $sku) {
        entity_id
        image_url
      }
    }
`;

export const generateKatalisImages = gql`
    mutation generateKatalisImages($input: GenerateKatalisImagesInput!) {
        generateKatalisImages(input: $input)
    }
`;

export const setDeletedKatalisImages = gql`
    mutation setDeletedKatalisImages($ids: [Int]!) {
        setDeletedKatalisImages(ids: $ids)
    }
`;

export const uploadTmpImage = gql`
  mutation uploadTmpImage($input: [uploadImageTmpInput]!) {
    uploadTmpImage(input: $input) {
      url
    }
  }
`;

export const registerKatalisAccount = gql`
  mutation {
    registerKatalisAccount {
      email
      password
    }
  }
`;

export const getKatalisAccount = gql`
  query getKatalisAccount {
    getKatalisAccount {
      email
      vendor_id
    }
  }
`;

export const getIsShopifyPublicAccount = gql`
  query getIsShopifyPublicAccount {
    getIsShopifyPublicAccount
  }
`;

export const getAddonsList = gql`
  query getAddonsList (
    $filter: AddonsListFilterInput
    $sort: AddonsListSortInput
    $pageSize: Int = 10
    $currentPage: Int = 1
  ){
    getAddonsList (
      filter: $filter
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      items { 
        entity_id
        addon_code
        addon_name
        addon_type
        logo
        features {
          en
          id
        }
        additional_config
        is_active
        integration_id
        is_connect
        integration_location {
          loc_id
          loc_name
        }
        my_location {
          loc_id
          loc_name
        }
        created_at
        updated_at
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;

export const updateIntegrationLocation = gql`
  mutation updateIntegrationLocation(
    $addon_id: Int!
    $loc_id: [Int]!
  ) {
    updateIntegrationLocation(
      addon_id: $addon_id
      loc_id: $loc_id
    )
  }
`;

export const getAuthUrlAddonChamber = gql`
  query getAuthUrlAddonChamber (
      $addon_code: String!
  ){
    getAuthUrlAddonChamber(
      system: $addon_code
    ){
      message
      status
      data
    }
  }
`;

export const setCredentialAddonChamber = gql`
  mutation setCredentialAddonChamber (
    $addon_code: String!
    $credentials: [inputCredentials]
  ){
    setCredentialAddonChamber(
      system: $addon_code
      credentials : $credentials
    ){
      message
      status
    }
  }
`;

export const setDisconnectAddonChamber = gql`
  mutation setDisconnectAddonChamber (
    $addon_code: String!
  ){
    setDisconnectAddonChamber(
      system : $addon_code
    ) {
      message
      status
    }
  }
`;

export const getOrderQueueStatusChamber = gql`
  query getOrderQueueStatusChamber(
    $system: String!
    $filter: OrderQueueChamberFilterInput
    $pageSize: Int
    $currentPage: Int
  ) {
    getOrderQueueStatusChamber(
      system: $system
      filter: $filter
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      items {
        channel_code
        channel_name
        channel_order_date
        channel_order_increment_id
        entity_id
        error_log
        marketplace_order_number
        remote_progress_status
        remote_sales_invoice_id
        remote_sales_order_id
        remote_sales_payment_id
        remote_sales_shipment_id
        status
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;

export const reQueueOrderChamber = gql`
  mutation reQueueOrderChamber(
    $system: String!
    $shipment_ids: [Int!]
  ) {
    reQueueOrderChamber(
      system: $system
      shipment_ids: $shipment_ids
    )
  }
`;

export const addQueueOrderChamber = gql`
  mutation addQueueOrderChamber(
    $system: String!
    $shipment_ids: [Int!]
  ) {
    addQueueOrderChamber(
      system: $system
      shipment_ids: $shipment_ids
    )
  }
`;

export default {
    getMarketplaceList,
    getSellerMpCredentials,
    getMarketplaceDefaultShippingMethods,
    connectSellerMp,
    requestSellerMarketplaceIntegration,
    getWebstoreList,
    getWebstoreConfiguration,
    createWebstore,
    registerBrandMp,
    integrateKatalisAccount,
    disconnectKatalisAccount,
    getKatalisImageBackgroundList,
    getKatalisCreditBalance,
    generateKatalisCopy,
    getGeneratedKatalisImagesbySKU,
    generateKatalisImages,
    setDeletedKatalisImages,
    uploadTmpImage,
    registerKatalisAccount,
    getKatalisAccount,
    getIsShopifyPublicAccount,
    getAddonsList,
    updateIntegrationLocation,
    getAuthUrlAddonChamber,
    setCredentialAddonChamber,
    setDisconnectAddonChamber,
    getOrderQueueStatusChamber,
    reQueueOrderChamber,
    addQueueOrderChamber,
};
